import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '@environments/environment';
import { appName, appVersion } from '@environments/shared';
import { PermissionsService } from '@zonar-ui/auth';
import { isLocalDevEnv } from '@app/modules/shared/utilities/utilities';
import { combineLatest, first, map, Observable, of, Subject, switchMap } from 'rxjs';
import { IUserProfile } from '@zonar-ui/auth/lib/models/user-profile.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { selectAssetCount, selectAssetsLoadState } from '@app/store/asset/selectors/assets.selectors';
import { selectCompany } from '@app/store/filters/selectors/filters.selectors';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  private user: User;
  userProfile$: Subject<IUserProfile[]> = new Subject<IUserProfile[]>();
  constructor(private permissions: PermissionsService, private store: Store<AppState>) {
    if (!isLocalDevEnv()) {
      // enable real user monitoring for all non-local (deployed) environments
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        env: environment.datadog.tagEnvironment,
        service: appName,
        version: appVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackViewsManually: false,
        traceSampleRate: 100,
        allowedTracingUrls: [
          /https:\/\/(?!support).*\.zonarsystems\.net/,
          url => url.startsWith(environment.apiBase.url)
        ],
        site: environment.datadogSite,
        useSecureSessionCookie: false,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['feature_flags']
      });

      datadogLogs.init({
        clientToken: environment.datadog.clientToken,
        env: environment.datadog.tagEnvironment,
        service: appName,
        site: environment.datadogSite,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: 'all',
        useSecureSessionCookie: false,
        useCrossSiteSessionCookie: true
      });

      this.permissions
        .getUsers()
        .pipe(first(users => Boolean(users?.length)))
        .subscribe(user => {
          this.user = user[0];
          datadogRum.setUser({
            id: this.user.id,
            email: this.user.email,
            sub: this.user.sub
          });
        });
    }
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addAction(name, context);
    }
  }

  setContextProperty(name: string, value: any) {
    if (!isLocalDevEnv()) {
      datadogRum.setGlobalContextProperty(name, value);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addError(error, context);
    }
  }

  /**
   * Sends a default RUM timing, or creates a RumTiming object which can be used for more precise measuring.
   * @param name The name of the timing
   * @param useView If true, sends a one-off RUM timing which counts from start time of the current RUM view.
   * If false, returns a RumTiming object which can be passed to sendRumTiming.
   * Defaults to true.
   * https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#add-your-own-performance-timing
   */
  newRumTiming(name: string, useView: boolean = true): RumTiming {
    if (!isLocalDevEnv()) {
      if (useView) {
        datadogRum.addTiming(name);
        return;
      }
      // Note: performance uses its own timestamp internally. However, we use the current epoch as a unique identifier.
      const markName = Date.now().toString();
      performance.mark(markName);
      return {
        name: name,
        markName: markName
      };
    }
  }

  /**
   * Sends a timing (as a RUM action), starting from when the RumTiming was created.
   * The RumTiming is not preserved, so additional timings will need their own unique RumTiming.
   * @param timing The RumTiming object to measure from
   */
  sendRumTiming(timing: RumTiming): void {
    if (!isLocalDevEnv()) {
      const measureName = Date.now().toString();
      try {
        performance.measure(measureName, timing.markName);
        datadogRum.addAction(timing.name, {
          [`${timing.name}_timing`]: performance.getEntriesByName(measureName)[0].duration
        });
        performance.clearMarks(timing.markName);
        performance.clearMeasures(measureName);
      } catch (error) {
        // if we get an error (such as because a mark doesn't exist), only throw if we are local,
        // otherwise the error will show in console errors in RUM on datadog
        // (note you would need to temporarily remove the !isLocalDevEnv check from methods to expose this)
        if (isLocalDevEnv()) throw error;
      }
    }
  }

  getRumContextDumpData(): Observable<ContextDump> {
    return combineLatest([
      this.permissions.getUsers().pipe(map(users => users[0])),
      this.permissions.getUserProfiles(),
      this.permissions
        .getDivisionMap()
        .pipe(
          map((divisionMap: any) => Object.values(divisionMap).filter((division: any) => division.status === 'ACTIVE'))
        ),
      this.store.select(selectCompany),
      this.store.select(selectAssetsLoadState),
      this.store.select(selectAssetCount)
    ]).pipe(
      switchMap(([user, userProfiles, divisions, company, assetsLoadState, assetCount]) => {
        const profileIds = userProfiles.map(profile => profile.id);
        const companies = userProfiles.map(profile => {
          return { allDivisions: profile.allDivisions, companyId: profile.companyId };
        });
        const divisionIds = divisions.map((division: any) => division.id);
        const obj: ContextDump = {
          email: user.email,
          userprofiles: profileIds,
          divisions: divisionIds,
          currentCompany: company.id,
          divisionsCount: divisions.length,
          multiCompanyUser: userProfiles.length > 1,
          assetsLoadState: assetsLoadState,
          assetCount: assetCount,
          companies: companies
        };
        return of(obj);
      }),
      map(response => {
        return response;
      })
    );
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes: object = {}): void {
    if (!isLocalDevEnv()) {
      datadogLogs.logger.info(message, attributes);
    }
  }

  logError(message: string, err: any, attributes: object = {}): void {
    if (!isLocalDevEnv()) {
      datadogLogs.logger.error(message, attributes, err);
    }
  }

  addFeatureFlagEvaluation(key, value) {
    datadogRum.addFeatureFlagEvaluation(key, value);
  }
}

export interface RumTiming {
  name: string;
  markName: string;
}
export interface ContextDumpCompany {
  allDivisions: boolean;
  companyId: string;
}
export interface ContextDump {
  email: string;
  userprofiles: string[];
  divisions: string[];
  currentCompany: string;
  divisionsCount: number;
  multiCompanyUser: boolean;
  assetsLoadState: string;
  assetCount: number;
  companies: ContextDumpCompany[];
}
