import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { LocalizationService } from './localization.service';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment/moment';
import { DeviceType } from '@app/modules/location-client/location-api.models';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { DISTANCE_UNITS } from '@environments/shared.models';
import { DistanceUnits, PathSpeedUnits } from '@app/modules/location/models/path-api.model';
import { ReverseGeocoderData } from '@app/modules/reverse-geocoder/services/models/reverse-geocoder-response.model';
import {
  KM_PER_METER,
  KPH_PER_MM_SEC,
  MILES_PER_METER,
  MPH_PER_MM_SEC,
  round,
  validGpsData
} from '@app/modules/shared/utilities/utilities';

enum ZTRAK_BATTERY_CONDITION {
  'GOOD',
  'LOW',
  'CRITICALLY_LOW'
}
@Injectable({
  providedIn: 'root'
})
export class UiUtilities implements OnDestroy {
  constructor(
    private translateService: TranslateService,
    private translations: Translations,
    private localization: LocalizationService
  ) {
    this.translations.translationsLoadState
      .pipe(filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL))
      .subscribe(() => {
        this.translated = this.translateService.instant([
          this.translations.uiUtilities.minutes,
          this.translations.uiUtilities.minute,
          this.translations.uiUtilities.mins,
          this.translations.uiUtilities.min,
          this.translations.uiUtilities.hours,
          this.translations.uiUtilities.hour,
          this.translations.uiUtilities.hrs,
          this.translations.uiUtilities.hr,
          this.translations.uiUtilities.days,
          this.translations.uiUtilities.day,
          this.translations.uiUtilities.weeks,
          this.translations.uiUtilities.week,
          this.translations.uiUtilities.months,
          this.translations.uiUtilities.month,
          this.translations.uiUtilities.years,
          this.translations.uiUtilities.year,
          this.translations.uiUtilities.ago,
          this.translations.uiUtilities.updated,
          this.translations.uiUtilities.updatedJustNow,
          this.translations.uiUtilities.justNow,
          this.translations.uiUtilities.lastUpdated,
          this.translations.uiUtilities.on,
          this.translations.uiUtilities.missingGpsData,
          this.translations.uiUtilities.connectionIssue,
          this.translations.uiUtilities.zTrak,
          this.translations.uiUtilities.poweredOff,
          this.translations.uiUtilities.idling,
          this.translations.uiUtilities.heading,
          this.translations.uiUtilities.away,
          this.translations.compassDirections.east,
          this.translations.compassDirections.north,
          this.translations.compassDirections.south,
          this.translations.compassDirections.west,
          this.translations.uiUtilities.ztrakBatteryCondition.good,
          this.translations.uiUtilities.ztrakBatteryCondition.low,
          this.translations.uiUtilities.ztrakBatteryCondition.criticallyLow,
          this.translations.uiUtilities.minuteAgoText,
          this.translations.uiUtilities.minutesAgoText,
          this.translations.uiUtilities.hourAgoText,
          this.translations.uiUtilities.hoursAgoText,
          this.translations.uiUtilities.dayAgoText,
          this.translations.uiUtilities.daysAgoText,
          this.translations.uiUtilities.weekAgoText,
          this.translations.uiUtilities.weeksAgoText,
          this.translations.uiUtilities.monthAgoText,
          this.translations.uiUtilities.monthsAgoText,
          this.translations.uiUtilities.yearAgoText,
          this.translations.uiUtilities.yearsAgoText
        ]);
        this.zTrakBatteryTranslations = {
          GOOD: this.translated[this.translations.uiUtilities.ztrakBatteryCondition.good],
          LOW: this.translated[this.translations.uiUtilities.ztrakBatteryCondition.low],
          CRITICALLY_LOW: this.translated[this.translations.uiUtilities.ztrakBatteryCondition.criticallyLow]
        };
        this.initCompassStrings();
      });
    this.unitMetric = this.localization.getUseMetricDistance() ? DISTANCE_UNITS.METRIC : DISTANCE_UNITS.IMPERIAL;
  }

  translated;
  private zTrakBatteryTranslations;
  unitMetric: DISTANCE_UNITS;
  compassDirections;
  compassDirectionsInitialized = false;

  onDestroy$ = new Subject<boolean>();
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  initCompassStrings() {
    if (this.compassDirectionsInitialized) {
      return;
    }

    const N = this.translated[this.translations.compassDirections.north];
    const S = this.translated[this.translations.compassDirections.south];
    const E = this.translated[this.translations.compassDirections.east];
    const W = this.translated[this.translations.compassDirections.west];
    this.compassDirections = [
      N,
      `${N}${N}${E}`,
      `${N}${E}`,
      `${E}${N}${E}`,
      E,
      `${E}${S}${E}`,
      `${S}${E}`,
      `${S}${S}${E}`,
      S,
      `${S}${S}${W}`,
      `${S}${W}`,
      `${W}${S}${W}`,
      W,
      `${W}${N}${W}`,
      `${N}${W}`,
      `${N}${N}${W}`
    ];
    this.compassDirectionsInitialized = true;
  }

  getTimeAgoString(timestamp: string, verbose: boolean): string {
    if (!timestamp) {
      return '';
    }

    const now = moment();
    const eventTs = moment(timestamp);
    const minutesAgo = now.diff(eventTs, 'minutes');
    const hoursAgo = now.diff(eventTs, 'hours');
    const daysAgo = now.diff(eventTs, 'days');
    const weeksAgo = now.diff(eventTs, 'weeks');
    const monthsAgo = now.diff(eventTs, 'months');
    const yearsAgo = now.diff(eventTs, 'years');
    let isPluralUnits = false;

    if (yearsAgo > 0) {
      isPluralUnits = yearsAgo !== 1;
      return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
        isPluralUnits
          ? this.translated[this.translations.uiUtilities.yearsAgoText].replace('#', yearsAgo)
          : this.translated[this.translations.uiUtilities.yearAgoText].replace('#', yearsAgo)
      }`;
    }

    if (monthsAgo > 0) {
      isPluralUnits = monthsAgo !== 1;
      return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
        isPluralUnits
          ? this.translated[this.translations.uiUtilities.monthsAgoText].replace('#', monthsAgo)
          : this.translated[this.translations.uiUtilities.monthAgoText].replace('#', monthsAgo)
      }`;
    }

    if (weeksAgo > 0) {
      isPluralUnits = weeksAgo !== 1;
      return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
        isPluralUnits
          ? this.translated[this.translations.uiUtilities.weeksAgoText].replace('#', weeksAgo)
          : this.translated[this.translations.uiUtilities.weekAgoText].replace('#', weeksAgo)
      }`;
    }

    if (daysAgo > 0) {
      isPluralUnits = daysAgo !== 1;
      return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
        isPluralUnits
          ? this.translated[this.translations.uiUtilities.daysAgoText].replace('#', daysAgo)
          : this.translated[this.translations.uiUtilities.dayAgoText].replace('#', daysAgo)
      }`;
    }

    if (hoursAgo > 0) {
      isPluralUnits = hoursAgo !== 1;
      return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
        isPluralUnits
          ? this.translated[this.translations.uiUtilities.hoursAgoText].replace('#', hoursAgo)
          : this.translated[this.translations.uiUtilities.hourAgoText].replace('#', hoursAgo)
      }`;
    }

    if (minutesAgo <= 0) {
      return verbose
        ? this.translated[this.translations.uiUtilities.updatedJustNow]
        : this.translated[this.translations.uiUtilities.justNow];
    }

    if (minutesAgo > 0) {
      isPluralUnits = minutesAgo !== 1;
      return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
        isPluralUnits
          ? this.translated[this.translations.uiUtilities.minutesAgoText].replace('#', minutesAgo)
          : this.translated[this.translations.uiUtilities.minuteAgoText].replace('#', minutesAgo)
      }`;
    }
  }

  getTimeAgoStringForDetailPage(
    timestamp: string,
    verbose: boolean,
    timeFormat = 'h:mm A',
    dateFormat = 'MM/DD/YY'
  ): string {
    if (!timestamp) {
      return '';
    }

    const now = moment();
    const eventTs = moment(timestamp);
    const minutesAgo = now.diff(eventTs, 'minutes');

    if (minutesAgo > 60) {
      const verboseFormat = `[${this.translated[this.translations.uiUtilities.lastUpdated]} ]${timeFormat} [${
        this.translated[this.translations.uiUtilities.on]
      }] ${dateFormat}`;
      const terseFormat = `${timeFormat} [${this.translated[this.translations.uiUtilities.on]}] ${dateFormat}`;
      return eventTs.format(verbose ? verboseFormat : terseFormat);
    }

    if (minutesAgo <= 0) {
      return verbose
        ? this.translated[this.translations.uiUtilities.updatedJustNow]
        : this.translated[this.translations.uiUtilities.justNow];
    }

    const isPluralUnits = minutesAgo !== 1;
    return `${verbose ? `${this.translated[this.translations.uiUtilities.updated]} ` : ''}${
      isPluralUnits
        ? this.translated[this.translations.uiUtilities.minutesAgoText].replace('#', minutesAgo)
        : this.translated[this.translations.uiUtilities.min].replace('#', minutesAgo)
    }`;
  }

  metersToMiles(meters): number {
    return meters ? meters * MILES_PER_METER : 0;
  }

  metersToKms(meters): number {
    return meters ? meters * KM_PER_METER : 0;
  }

  mmPerSecToMph(speed): number {
    // convert asset speed in mm/sec to mph rounded to nearest whole value
    return speed * MPH_PER_MM_SEC;
  }

  mmPerSecToKph(speed): number {
    return speed * KPH_PER_MM_SEC;
  }

  convertSpeed(speed): number | string {
    const convertedSpeed =
      this.unitMetric == DISTANCE_UNITS.IMPERIAL ? this.mmPerSecToMph(speed) : this.mmPerSecToKph(speed);
    return !Number.isNaN(convertedSpeed) ? Math.round(convertedSpeed) : '???';
  }

  formatSpeed(speed): string {
    const units = this.getSpeedUnit();
    const convertedSpeed = this.convertSpeed(speed);
    return `${convertedSpeed} ${units.toLowerCase()}`;
  }

  convertDistance(meters): number {
    return this.unitMetric == DISTANCE_UNITS.IMPERIAL ? this.metersToMiles(meters) : this.metersToKms(meters);
  }

  getSpeedUnit(): PathSpeedUnits {
    return this.unitMetric == DISTANCE_UNITS.IMPERIAL ? PathSpeedUnits.MPH : PathSpeedUnits.KPH;
  }
  assetSubtitle(asset) {
    if (!validGpsData(asset)) {
      return this.translated[this.translations.uiUtilities.missingGpsData];
    }
    if (asset?.connectionIssue) {
      return this.translated[this.translations.uiUtilities.connectionIssue];
    }
    if (asset?.gpssn && this.gpssnToGpsType(asset?.gpssn) === DeviceType.ZTRAK) {
      return this.translated[this.translations.uiUtilities.zTrak];
    }
    if (!asset.powerOn) {
      return this.translated[this.translations.uiUtilities.poweredOff];
    }
    if (asset.powerOn && asset.idling) {
      return this.translated[this.translations.uiUtilities.idling];
    }
    // powered on and not idling, we're in motion
    return `${this.translated[this.translations.uiUtilities.heading]} ${this.degToCompass(
      asset.heading
    )} - ${this.formatSpeed(asset.speed)}`;
  }

  assetOdometerMessage(distance: number): String {
    const units = this.unitMetric === DISTANCE_UNITS.IMPERIAL ? DistanceUnits.MILE : DistanceUnits.KM;
    const convertedDistanceFromMeters = this.convertDistance(distance);
    const localizedNumber = this.localization.setLocalizedNumberFormatWithPrecision(convertedDistanceFromMeters, 0);
    return `${localizedNumber} ${units}`;
  }
  assetSidebarMessage(distance: number): string {
    const units = this.unitMetric === DISTANCE_UNITS.IMPERIAL ? DistanceUnits.MILE : DistanceUnits.KM;
    return `${distance >= 10 ? distance?.toFixed(0) : distance?.toFixed(1)} ${units}. ${
      this.translated[this.translations.uiUtilities.away]
    }`;
  }

  formatDuration(milliseconds, trim = true): string {
    const durationTemplate =
      milliseconds / 1000 / 60 > 60
        ? `h [${this.translated[this.translations.uiUtilities.hrs]}], m [${
            this.translated[this.translations.uiUtilities.mins]
          }]`
        : `m [${this.translated[this.translations.uiUtilities.mins]}]`;
    return moment.duration(milliseconds, 'milliseconds').format(durationTemplate, { trim: trim ? 'all' : 'large' });
  }

  formatDistance(meters): string {
    const units = this.getSpeedUnit();
    const distance = Math.round(this.convertDistance(meters));
    let suffix;
    if (distance === 1) {
      suffix = units === PathSpeedUnits.MPH ? 'mile' : 'km';
    } else {
      suffix = units === PathSpeedUnits.MPH ? 'miles' : 'kms';
    }
    return `${distance} ${suffix}`;
  }

  getSpeedSuffix() {
    const units = this.getSpeedUnit();
    return units === PathSpeedUnits.MPH ? 'mph' : 'kph';
  }

  gpssnToGpsType(gpssn: string | number) {
    const gpsSnToTypeMap = [
      { low: 1000, high: 1007472, type: 'HDGPS' },
      { low: 2000000, high: 2999999, type: 'V2, V2J' },
      { low: 3000000, high: 3999999, type: 'V2J (FGA)' },
      { low: 4000000, high: 4319999, type: 'V2' },
      { low: 4320000, high: 4499999, type: 'V2, high: K12' },
      { low: 4500000, high: 4999999, type: 'K12J' },
      { low: 5000000, high: 5999999, type: 'V2J' },
      { low: 6000000, high: 6999999, type: 'VT ECU' },
      { low: 7000000, high: 7009999, type: 'Wombat V2' },
      { low: 7010000, high: 7499999, type: 'V3R' },
      { low: 8000000, high: 8999999, type: 'V3' },
      { low: 11000000, high: 11999999, type: 'VTHU' },
      { low: 12000000, high: 12999999, type: 'V3i' },
      { low: 13000000, high: 13200000, type: 'VT 500' },
      { low: 14000000, high: 14010000, type: 'V3 500' },
      { low: 17000000, high: 17999999, type: 'VTHU' },
      { low: 18000000, high: 18999999, type: 'V4' },
      { low: 21000000, high: 21999999, type: 'V4' },
      { low: 22000000, high: 22999999, type: 'VT4' },
      { low: 23000000, high: 23999999, type: 'VT4 FSA' },
      { low: 25000000, high: 25999999, type: 'cTP' },
      { low: 26000000, high: 26999999, type: 'V4R' },
      { low: 27000000, high: 27999999, type: 'V4 Base' },
      { low: 30000000, high: 30999999, type: 'V4 Essential' },
      { low: 33000000, high: 33999999, type: 'V4C' },
      { low: 34000000, high: 34999999, type: 'V4E' },
      { low: 35000000, high: 35099999, type: 'Navistar' },
      { low: 50000000, high: 50999999, type: 'CTP3' },
      { low: 3000000000, high: 3999999999, type: 'zTrak' },
      { low: 4000000000, high: 4000010000, type: 'Zonar R&D' },
      { low: 16042000000000, high: 16042009999999, type: 'LD TCU' },
      { low: 16350000000000, high: 16350004999999, type: 'LD25-V01' },
      { low: 350457790000000, high: 350457799999999, type: 'zTrak' },
      { low: 351358810000000, high: 351358819999999, type: 'zTrak' },
      { low: 351516170000000, high: 351516179999999, type: 'zTrak' },
      { low: 351901930000000, high: 351901939999999, type: 'zTrak' },
      { low: 358447170000000, high: 358447179999999, type: 'zTrak' },
      { low: 359746160000000, high: 359746169999999, type: 'zTrak' },
      { low: 353731110000000, high: 353731119999999, type: 'LD TCU' },
      { low: 353732110000000, high: 353732119999999, type: 'LD TCU' },
      { low: 860264050000000, high: 867648049999999, type: 'RDL 4G' }
    ];

    if (!gpssn) {
      return 'UNKNOWN';
    }
    const regex = /\d{4}\.[0-1]{10}\d{9}B[0-1]{7}\d/;
    if (regex.test(gpssn.toString())) {
      return 'VDO Link';
    }
    const ssnAsNumber = +gpssn;
    for (const gpsType of gpsSnToTypeMap) {
      if (ssnAsNumber >= gpsType.low && ssnAsNumber <= gpsType.high) {
        return gpsType.type;
      }
    }

    return 'UNKNOWN';
  }

  getTranslatedBatteryCondition(ztrakBatteryCondition: number): string {
    return this.zTrakBatteryTranslations[ZTRAK_BATTERY_CONDITION[ztrakBatteryCondition]];
  }

  degToCompass(heading) {
    // convert heading (100ths of deg) to 1 of 16 cardinal directions. Compass is treated as 16, 22.5 degree
    // wide slices centered around the 16 cardinal directions. For example, a heading of 60 degrees would
    // fall into the ENE slice because it is within +/- 11.25 degrees of 67.5 degrees (ENE).
    return this.compassDirections[Math.round(heading / 100 / 22.5) % 16];
  }

  getCompassforIcon(heading) {
    const compassIcon = Math.round(heading / 100 / 22.5) % 16;
    switch (compassIcon) {
      case 0:
        return 'N';
      case 1:
        return 'NNE';
      case 2:
        return 'NE';
      case 3:
        return 'ENE';
      case 4:
        return 'E';
      case 5:
        return 'ESE';
      case 6:
        return 'SE';
      case 7:
        return 'SSE';
      case 8:
        return 'S';
      case 9:
        return 'SSW';
      case 10:
        return 'SW';
      case 11:
        return 'WSW';
      case 12:
        return 'W';
      case 13:
        return 'WNW';
      case 14:
        return 'NW';
      case 15:
        return 'NNW';
    }
  }

  getAddressText(geocodeData: ReverseGeocoderData) {
    return geocodeData.address || 'Address Unknown';
  }

  getRoundedPercentText(value: number): string {
    if (value == null) {
      return '';
    }
    const rounded: number = round(value ?? 0, 1);
    return rounded.toString() + '%';
  }
}
