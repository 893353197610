<div ngClass.gt-sm="app-container-desktop"
     ngClass.lt-md="app-container-mobile"
     fxLayout="column"
     fxFill>

  <zui-sidenav [sidenavParams] = "sidenavParams"
               [header]="sidenavHeader"
               [menu]="sidenavMenu"
               [footer]="sidenavFooter"
               (emitSelectedCompany)="setCurrentCompanyByFormSelection($event)">
    <div class="sidenav-container app-bg-color"
         fxFlex
         fxLayout="column">

      <zui-sidenav-header class="header-bar-sidenav" id="sidenav-header"
                          (menuToggled)="onSideNavMobileMenuButtonToggled($event)"
                          [hideMobileMenuButton]="hideMobileMenuButton"></zui-sidenav-header>

      <div class="content-container app-bg-color"
           fxFlex>

        <router-outlet>
          <div class="loading-app-spinner"
               *ngIf="loadingAnimationService.shouldShowMainAppSpinner"></div>
        </router-outlet>
      </div>
    </div>

  </zui-sidenav>

</div>